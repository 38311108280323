@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* font-family: "Inter", sans-serif; */
    @apply h-full;
    @apply bg-slate-100;
  }
  body {
    @apply h-full;
  }
}